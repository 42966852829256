/**
 * Created by ximing on 2018/10/16.
 */
'use strict';
export const fetchResources = async function (source){
    if(!/\.json$/.test(source)){
        source += '.json';
    }
    let rs = await fetch(source).then(res => {
        return res.text();
    });
    rs = rs.replace(/\\/g, '\\\\');
    let resInfo = {items: []};
    try{
        resInfo = JSON.parse(rs);
    }catch(e){
        window.console.error(source, e);
    }
    let list = resInfo.items || [];
    list.forEach(item => {
        item.from = resInfo.title;
        item.fromUrl = resInfo.home_page_url;

        item.image = item.image || item.banner_image || (/https?:\/\/([\s\S]+?)\.(jpg|png|gif|webp)/i.exec(item.content_html || item.summary) || [])[0];
    })
    return list;
}