/**
 * Created by ximing on 2018/10/16.
 */
'use strict';
import React,{Component} from 'react';
import { Row, Col } from 'antd';
import {fetchResources} from '../utils/api'
import config from '../config'
import Item from './item';

export default class WaterfallComponent extends Component {

    state = {
        sources:[]
    };

    componentDidMount(){
        let sources = [];
        config.rss.forEach(item=>sources = sources.concat(item.children));
        sources.forEach(item=>{
            fetchResources(item.source).then(list=>{
                item.list = list;
                this.setState({
                    sources
                })
            })
        });
        window.source= sources;
    }

    render(){
        const list = this.state.sources.reduce((accumulator,currentValue)=>{
            return accumulator.concat(currentValue.list||[]);
        },[]);
        let chunkList = window._.chunk(list, 4);

        return (
            <div>
                {
                    chunkList.map(item=> {
                        return (
                            <Row gutter={{ xs: 8, sm: 16, md: 24}}>
                                {
                                    item.map(detail=><Col  xs={12} sm={12} md={12} lg={6} xl={6}>
                                        <Item key={item.id} detail={detail} />
                                    </Col>)
                                }
                            </Row>
                        )
                    })
                }
            </div>
        )
    }
}