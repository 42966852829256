import React, { Component } from 'react';
import 'antd/dist/antd.less';
import './style/main.less';
import List from './components/list';
import Header from './components/header';
import { Drawer } from 'antd';

class App extends Component {

  state = {
    menuVisible : false
  }

  openMenu = ()=>{
    this.setState({
        menuVisible : true
    })
  }

  onClose = ()=>{
      this.setState({
          menuVisible : false
      })
  }

  render() {
    return (
      <div className="App">
          <Header openMenu={this.openMenu}/>
          <Drawer   title="rss 设置"
                    placement="left"
                    closable={true}
                    onClose={this.onClose}
                    visible={this.state.menuVisible}/>
          <List />
      </div>
    );
  }
}

export default App;
