/**
 * Created by ximing on 2018/10/16.
 */
'use strict';
import React, { Component } from 'react';
import {Icon} from 'antd';

export default class Header extends Component {
    render(){
        return(
            <div className="rss-header">
                <button onClick={this.props.openMenu} className="rss-menu-btn"><Icon type="menu-unfold" theme="outlined" /></button>
                <div className="header-title">今天看一看</div>
            </div>
        )
    }
}