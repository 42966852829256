/**
 * Created by ximing on 2018/10/16.
 */
'use strict';
export default {
    rss:[{
        "name": "技术",
        "source": "",
        "icon": "github",
        "children": [
            {
                "name": "掘金前端",
                "source": "https://rsshub.app/juejin/category/frontend.json"
            },
            {
                "name": "掘金本月前端",
                "source": "https://rsshub.app/juejin/trending/frontend/monthly.json"
            },
            {
                "name": "掘金AI",
                "source": "https://rsshub.app/juejin/category/ai"
            },
            {
                "name": "V2EX",
                "source": "https://rsshub.app/v2ex/topics/latest.json"
            },
            {
                "name": "V2EX最热",
                "source": "https://rsshub.app/v2ex/topics/hot.json"
            },
            {
                "name": "简书七日热门",
                "source": "https://rsshub.app/jianshu/trending/weekly.json"
            }

        ]
    },
        {
            "name": "知乎收藏",
            "source": "",
            "icon": "zhihu",
            "children": [
                {
                    "name": "知乎看热闹",
                    "source": "https://rsshub.app/zhihu/collection/100960447.json"
                },
                {
                    "name": "装逼指南",
                    "source": "https://rsshub.app/zhihu/collection/71859050.json"
                },
                {
                    "name": "有空再看",
                    "source": "https://rsshub.app/zhihu/collection/32147143.json"
                },
            ]
        },
        {
            "name": "新番",
            "source": "",
            "icon": "youtube",
            "children": [
            ]
        },
        {
            "name": "大新闻",
            "source": "",
            "icon": "profile",
            "children": [
            ]
        }]
}