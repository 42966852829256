/**
 * Created by ximing on 2018/10/16.
 */
'use strict';
import React, { Component } from 'react';
import { Card,Icon } from 'antd';
const {XmlEntities} = require('html-entities');
const entities = new XmlEntities();

export default class Item extends Component{
    render(){
        console.log(this.props.detail)
        const {title,url,from,summary} = this.props.detail;
        return (
            <a href={url} target="_blank" className="rss-item" >
                <Card bordered={false} title={
                    <div className="rss-item-header">
                        <div className="rss-item-header-title">{title}</div>
                        <div className="rss-item-header-tags">
                            <Icon type="radar-chart" theme="outlined" />{from}
                        </div>
                    </div>
                } bordered={false} >
                    <div className="rss-item-content" dangerouslySetInnerHTML={{__html:entities.decode(summary)}}></div>
                </Card>
            </a>
        )
    }
}